<template>
  <div :style="'background: ' + webset['homebakcolor']" class="home-wrapper">
    <search :logo="webset.logo" :webset="webset" leimulist="topmenu" />

    <div style="font-size: 20px; text-align: center; margin-left: 10%; margin-right: 10%;font-size: 17px;">
      <p style="font-size: 20px; color: red; margin-top: 4%">{{ $t('About us') }}</p>
      <p>{{ $t('about_us_intro1') }}</p>
      <p>{{ $t('about_us_intro2') }}</p>
      <p>{{ $t('about_us_intro3') }}</p>
    </div>

    <div class="banner w600" style="text-align: center;margin-bottom:100px;">
      <img src="@/assets/images/1.jpg" alt="Image 1" class="responsive-image"
        style="margin-top: 3%; width: 853px; height: 412px" />
    </div>

    <div class="banner w600" style="text-align: center;margin-bottom:100px;">
      <img src="@/assets/images/2.jpg" alt="Image 2" class="responsive-image"
        style="margin-top: 3%; width: 853px; height: 412px" />
    </div>

    <div class="banner w600" style="text-align: center;margin-bottom:100px;">
      <img src="@/assets/images/3.jpg" alt="Image 3" class="responsive-image"
        style="margin-top: 3%; width: 853px; height: 412px" />
    </div>

    <div style="font-size: 20px; text-align: center; margin: 5% 5%">
      <p style="font-size: 20px; color: red; margin-top: 4%;">{{ $t('our_vision') }}</p>
      <div style="margin-left: auto; margin-right: auto; width: 853px; text-align: left;font-size: 17px;">
        <p>{{ $t('vision_point1') }}</p>
        <p>{{ $t('vision_point2') }}</p>
        <p>{{ $t('vision_point3') }}</p>
      </div>
    </div>

    <div class="banner w600" style="text-align: center;margin-bottom:100px;">
      <img src="@/assets/images/4.jpg" alt="Image 4" class="responsive-image"
        style="margin-top: 3%; width: 853px; height: 412px" />
    </div>

    <div class="banner w600" style="text-align: center;margin-bottom:100px;">
      <img src="@/assets/images/5.jpg" alt="Image 5" class="responsive-image"
        style="margin-top: 3%; width: 853px; height: 412px" />
    </div>

    <div class="banner w600" style="text-align: center; margin:100px;">
      <img src="@/assets/images/6.jpg" alt="Image 6" class="responsive-image"
        style="margin-top: 3%; width: 853px; height: 412px" />
    </div>

  </div>
</template>


<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from "@/components/Search";
import Product from "@/components/Product";

export default {
  name: "Home",
  components: { Search, Product },

  created() {
    console.log("about us.");

    this.getuniacid();
    if (this.$route.query.code) {
      console.log("leimu:", this.$route.query.code);
      this.nowleimu = this.$route.query.code;
    }

    //this.getGoodsList()
  },
  methods: {
    // 获取首頁模块
    async getuniacid() {
      // this.uniacid= 4 ;
      var ss = window.localStorage.getItem("webinfo");

      var ssarray = JSON.parse(ss);
      console.log("ss", ss);
      console.log("ssarray.ucid", ssarray["ucid"]);
      this.webset = ssarray;
    },
  },
};
</script>


<!-- <style lang="scss" scoped>
.home-wrapper {
  .about-us {
    font-size: 17px;
    text-align: center;
    margin: 5% 5%;

    .title {
      font-size: 20px;
      color: red;
      margin-top: 4%;
    }

    .content {
      margin-bottom: 1rem;
    }
  }

  .banner {
    text-align: center;
    margin-bottom: 100px;

    img {
      width: 100%;
      max-width: 853px;
      height: auto;

      //
      max-height: 500px;
      display: block;
      margin: 0 auto;
      //
    }
  }

  .our-vision {
    font-size: 17px;
    text-align: center;
    margin: 5% 5%;

    .title {
      font-size: 20px;
      color: red;
      margin-top: 4%;
    }

    .content-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 853px;
      text-align: left;
    }

    .content {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 767px) {
  .home-wrapper {
    .about-us,
    .our-vision {
      margin: 5% 2%;
    }

    .banner {
      margin-bottom: 50px;

      img {
        max-width: 100%;
        height: auto;
        max-height: 300px;
      }
    }
  }
}
</style> -->


<style lang="scss" scoped>
.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px; // add some padding for better spacing

  .about-us,
  .our-vision {
    font-size: 16px; // reduce font size slightly
    width: 100%;
    max-width: 428px; // iPhone 14 Pro Max screen width
    margin-bottom: 40px; // increase vertical spacing

    .title {
      font-size: 18px; // reduce title font size
      color: red;
      margin-top: 4%;
    }

    .content {
      margin-bottom: 1rem;
    }
  }

  .banner {
    text-align: center;
    width: 100%;
    max-width: 428px; // iPhone 14 Pro Max screen width
    margin-bottom: 60px; // increase vertical spacing

    img {
      display: block;
      margin: 0 auto; // center the image
      width: 100%;
      height: auto;
      max-width: 428px; // iPhone 14 Pro Max screen width
      max-height: 300px; // reduce max height for better fit
    }
  }

  .our-vision {
    .content-wrapper {
      text-align: left;
    }
  }
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

@media (max-width: 767px) {
  .home-wrapper {
    .about-us,
    .our-vision {
      width: 96%;
      margin-left: 2%;
      margin-right: 2%;
    }

    .banner {
      width: 100%;
      max-width: 100%;
      margin-bottom: 30px; // reduce vertical spacing for smaller screens
    }
  }
}
</style>