<template>
  <div :style="'background: ' + webset['homebakcolor']" class="home-wrapper">
    <search :logo="webset.logo" :webset="webset" leimulist="topmenu" />

    <!-- Service Brands Section -->
    <div class="service-brands">
      <h1>{{ $t('Service Provider2') }}</h1>
      
      <div class="intro">
        {{ $t('service_provider_intro1') }}
      </div>

      <div class="intro">
        {{ $t('service_provider_intro2') }}
      </div>

      <div class="brands">
        <div class="brand-item">
          <span class="brand-code">CMHK</span>
          <span class="brand-name">中國移動</span>
        </div>
        <div class="brand-item">
          <span class="brand-code">HKBN</span>
          <span class="brand-name">香港寬頻</span>
        </div>
        <div class="brand-item">
          <span class="brand-code">HKT</span>
          <span class="brand-name">香港電訊</span>
        </div>
        <div class="brand-item">
          <span class="brand-code">SmarTone</span>
          <span class="brand-name">數碼通</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import chineseLanguageLoader from "chinese-language-loader/lib/main.js";
import Search from "@/components/Search";
import Product from "@/components/Product";

export default {
  name: "Home",
  components: { Search, Product },

  created() {
    console.log("about us.");

    this.getuniacid();
    if (this.$route.query.code) {
      console.log("leimu:", this.$route.query.code);
      this.nowleimu = this.$route.query.code;
    }

    //this.getGoodsList()
  },
  methods: {
    // 获取首頁模块
    async getuniacid() {
      // this.uniacid= 4 ;
      var ss = window.localStorage.getItem("webinfo");

      var ssarray = JSON.parse(ss);
      console.log("ss", ss);
      console.log("ssarray.ucid", ssarray["ucid"]);
      this.webset = ssarray;
    },
  },
};
</script>


<!-- <style lang="scss" scoped>
.home-wrapper {
  .about-us {
    font-size: 17px;
    text-align: center;
    margin: 5% 5%;

    .title {
      font-size: 20px;
      color: red;
      margin-top: 4%;
    }

    .content {
      margin-bottom: 1rem;
    }
  }

  .banner {
    text-align: center;
    margin-bottom: 100px;

    img {
      width: 100%;
      max-width: 853px;
      height: auto;

      //
      max-height: 500px;
      display: block;
      margin: 0 auto;
      //
    }
  }

  .our-vision {
    font-size: 17px;
    text-align: center;
    margin: 5% 5%;

    .title {
      font-size: 20px;
      color: red;
      margin-top: 4%;
    }

    .content-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 853px;
      text-align: left;
    }

    .content {
      margin-bottom: 1rem;
    }
  }
}

@media (max-width: 767px) {
  .home-wrapper {
    .about-us,
    .our-vision {
      margin: 5% 2%;
    }

    .banner {
      margin-bottom: 50px;

      img {
        max-width: 100%;
        height: auto;
        max-height: 300px;
      }
    }
  }
}
</style> -->


<style lang="scss" scoped>
.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;

  .service-brands {
    width: 100%;
    max-width: 428px;
    margin-bottom: 40px;
    font-family: "Microsoft JhengHei", Arial, sans-serif;

    h1 {
      font-size: 24px;
      margin-bottom: 30px;
    }

    .intro {
      margin-bottom: 20px;
      text-align: left;
      line-height: 1.6;
    }

    .brands {
      margin-top: 30px;
      
      .brand-item {
        display: flex;
        margin-bottom: 15px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        
        .brand-code {
          font-weight: bold;
          min-width: 120px;
        }
        
        .brand-name {
          flex: 1;
          text-align: left;
        }
      }
    }
  }

  .about-us,
  .our-vision {
    font-size: 16px;
    width: 100%;
    max-width: 428px;
    margin-bottom: 40px;

    .title {
      font-size: 18px;
      color: red;
      margin-top: 4%;
    }

    .content {
      margin-bottom: 1rem;
    }
  }

  .banner {
    text-align: center;
    width: 100%;
    max-width: 428px;
    margin-bottom: 60px;

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: auto;
      max-width: 428px;
      max-height: 300px;
    }
  }

  .our-vision {
    .content-wrapper {
      text-align: left;
    }
  }
}

@media (max-width: 767px) {
  .home-wrapper {
    .service-brands,
    .about-us,
    .our-vision {
      width: 96%;
      margin-left: 2%;
      margin-right: 2%;
    }

    .banner {
      width: 100%;
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
}
</style>