<template>
    <div class="product-list-wrapper">
        <search />
        <div class="w1200">
           <template v-if="goodsList.length">
               <div class="content flex-bt">
                    <product v-for="(item,index) in goodsList" :key="index"  :product="item" />
               </div>
               
           </template>
           <template v-else>
               <el-empty :description="$t('NO DATA')"></el-empty>
           </template>
        </div>
    </div>
</template>
<script>
import Search from '@/components/Search'
import Product from '@/components/Product'
export default {
    components: {Search, Product},
    data() {
        return {
            keyword: '',
            goodsList: [],
            page: 1,
            size: 100,
            page: 1,
            pageCount: 0,
            filterList: [],
            layout: 'col',
            filterListCondition: {},
            sort: '',
            statusBarHeight: '',
            showFilterPanel: false,
            isInitComplete: false,
            isLoading: false,
            isRefreshing: false
        }
    },
    created(){
        this.keyword = this.$route.params.keyword || ''
        this.keyword && this.getGoodsList()
    },
    methods: {
        async getGoodsList() {
            if (this.page > 1 && this.page - 1 >= this.pageCount) return
            this.isLoading = true
            const res = await this.$http.get('/goods', {
                page: this.page++,
                size: this.size,
                sort: this.sort,
                keyword: this.keyword,
                uniacid: window.localStorage.getItem('uniacid'),
                ...this.filterListCondition,
            })
            if (res.code !== 0) {
                return uni.showToast({
                    title: res.data.message,
                    icon: 'none'
                })
            }
            let data = res.data
            this.pageCount = Math.ceil(data.total / this.size)
            if (this.page <= 2) this.goodsList = []
            this.goodsList = this.goodsList.concat(data.list)
        },
    }

}
</script>
<style lang="scss" scoped>
    .product-list-wrapper{
        .content{
            box-sizing: border-box;
            padding: 30px 0;
            flex-wrap: wrap;
            // background: #fff;
        }
    }
</style>